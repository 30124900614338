import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import { components } from 'react-select';
import Loader from '../Containers/Loader';
import defProdImage from '../../static/DPImages/productImg.png';
import Parser from 'html-react-parser';
import { Modal } from 'react-bootstrap';
import { MdClose, MdOutlineClose } from 'react-icons/md';
import ReactImageMagnify from 'react-image-magnify';

ReactSession.setStoreType("localStorage");

const SearchMedVenAvl = () => {
  const navigate = useNavigate();
  const [serCat, setSerCat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [orderCompleteMessage, setOrderCompleteMessage] = useState("");
  const [avalVendors, setAvalVendors] = useState(0);

  const [alignProdModal, setAlignProdModal] = useState(false);
  const alignProdModalShow = () => {
    setFillAvailableVendor(availableVendor);
    setAlignProdModal(true);
  }

  const alignProdModalClose = () => {
    setAlignProdModal(false);
  }

  //  Align Vendor with product
  const alignProduct = () =>{
    setIsLoading(true);

    var errorElements = [];

    if (document.getElementById("alignProdID").value === "")
      errorElements.push("alignProdName");

    if (document.getElementById("alignVendID").value === "")
      errorElements.push("alignProdVend");

    
    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    setIsLoading(false);
    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      // alignProdID, vend_ID, user_Id
      setIsLoading(true);
      fetch('http://43.205.199.21/digiPartnerAPI/', {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          alignProdID: document.getElementById('alignProdID').value,
          user_Id : ReactSession.get("id"),
          vend_ID: document.getElementById("alignVendID").value,
        })
      })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }
        else if (data.status === "success") { 
          alert("Vendor sucessfully aligned");
          setAlignProdModal(false);
        }
        stockistModalClose();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
      });
    }
  }

  const [vendorType, setVendorType] = useState(0);
  const [stockistModal, setStockistModal] = useState(false);
  const stockistModalClose = () => setStockistModal(false);
  const stockistModalShow = (vnType) => {
    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        setVendorType(vnType);
        setStockistModal(true);
      }
  }

    // Stockist/CFA create
  // stockName gstin dlNo dlNo2 eMail mobile mobile2 address1 address2 city state pincode
  const addStockist = () => {
    setIsLoading(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        stockName: document.getElementById('stockName').value,
        dp_id : ReactSession.get("id"),
        gstin: document.getElementById('gstin').value,
        dlNo: vendorType === 0 ? document.getElementById('dlNo').value : '',
        dlNo2: vendorType === 0 ? document.getElementById('dlNo2').value : '',
        email: document.getElementById('eMail').value,
        mobile: document.getElementById('mobile').value,
        mobile2: document.getElementById('mobile2').value,
        address1: document.getElementById('address1').value,
        address2: document.getElementById('address2').value,
        city: document.getElementById('city').value,
        state: document.getElementById('state').value,
        pincode: document.getElementById('pincode').value,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }
        else if (data.status === "success") { 
          var msg = (vendorType === 0 ? "Stockist/PFA" : "Customer") + " Successfully Added";
          alert(msg);
        }
        stockistModalClose();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  //Validation add stockist/pfs name form  
  const validatRegForm = () => {
    var errorElements = [];
    var dlNo = vendorType === 0 ? document.getElementById("dlNo").value : '';
    var dlNo2 = vendorType === 0 ? document.getElementById("dlNo2").value : '';
    var gstNo = document.getElementById("gstin").value;

    var apiURL = 'http://43.205.199.21/digiPartnerAPI?dlno=' + dlNo + '&dlNo2=' + dlNo2 + '&gstNo=' + gstNo;

    fetch(apiURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      // stockName gstin dlNo dlNo2 mobile address1
      if (document.getElementById("stockName").value === "")
        errorElements.push("stockName");

      if (vendorType === 0) {
        // if (document.getElementById("gstin").value === "")
        //   errorElements.push("gstin");

        if (document.getElementById("dlNo").value === "")
          errorElements.push("dlNo");

        if (document.getElementById("dlNo2").value === "")
          errorElements.push("dlNo2");
      }
      
      if (document.getElementById("address1").value === "")
        errorElements.push("address1");

      if (document.getElementById("city").value === "")
        errorElements.push("city");

      if (document.getElementById("state").value === "")
        errorElements.push("state");
      
      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage2").innerHTML += "<br />Mobile must contain 10 digits";
        }
  
      if (resp.dlno > 0 && vendorType === 0) {
        errorElements.push("dlNo");
        errorElements.push("dlNo2");
        document.getElementById("errorMessage2").innerHTML += "<br />Drug License number already exist..!";
      }

      if (resp.gstNo > 0) {
        errorElements.push("gstin");
        document.getElementById("errorMessage2").innerHTML += "<br />GSTIN already exist..!";
      }

      errorElements.forEach(showError);
      function showError(item) {
        document.getElementById(item).classList.add("errorField");
      }

      if (errorElements.length > 0) {
        document.getElementById("errorMessage2").classList.replace("errorMessageHide", "errorMessage");
        document.getElementById(errorElements[0]).focus();
        return false;
      }
      else {
        document.getElementById("errorMessage2").classList.replace("errorMessage", "errorMessageHide");
        addStockist();
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }


  // Add new Products
  //newProdName newProdUnit newProdComp newProdMRP
  const [AddProdModal, setAddProdModal] = useState(false);
  const AddProdModalClose = () => setAddProdModal(false);
  const AddProdModalShow = () => setAddProdModal(true);

  const AddProduct = () => {
    setIsLoading(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        dp_id: ReactSession.get("id"),
        newProdName: document.getElementById('newProdName').value,
        newProdUnit: document.getElementById('newProdUnit').value,
        newProdComp: document.getElementById('newProdComp').value,
        newProdMRP: document.getElementById('newProdMRP').value,
      })
    })
    .then((response) => response.json())
    .then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else if (resp.status === "success") { 
        alert("Product Successfully Added");
      }
      setIsLoading(false);
      AddProdModalClose();
      searchProdVendorWise(selectedVendor);
    })
    .catch((err) => {
      setIsLoading(false);
      console.log(err.message);
    });
  }

  
  //Validation new prod form
  // newProdName newProdUnit newProdComp newProdMRP
  const validatAddProdForm = () => {
    setIsLoading(true);

    var errorElements = [];

    if (document.getElementById("newProdName").value === "")
      errorElements.push("newProdName");

    if (document.getElementById("newProdUnit").value === "")
      errorElements.push("newProdUnit");

    if (document.getElementById("newProdComp").value === "")
      errorElements.push("newProdComp");

    if (document.getElementById("newProdMRP").value === "")
      errorElements.push("newProdMRP");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    setIsLoading(false);
    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      AddProduct();
    }
  }

  
  // modal
  const [showModal, setShowModal] = useState(false);

  const [fillVendorWiseProdAvl, setFillVendorWiseProdAvl] = useState(null);
  const [vendorWiseProdImages, setVendorWiseProdImages] = useState({ "": "" });
  const [vendorWiseProdAvl, setVendorWiseProdAvl] = useState(null);
  const [availableVendor, setAvailableVendor] = useState(null);
  const [fillAvailableVendor, setFillAvailableVendor] = useState(null);
  const [digiCartItems, setDigiCartItems] = useState(null);
  const [cartOrderComp, setCartOrderComp] = useState(null);
  const [totals, setTotals] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [QRCode, setQRCode] = useState(null);
  const [trendProducts, setTrendProducts] = useState(null);
  const [trendFiles, setTrendFiles] = useState(null);

  const [productsData, setProductsData] = useState(null);
  const [isSaltInformationPageShow, setIsSaltInformationPageShow] = useState(false);
  const [isSubstitutesPageShow, setIsSubstitutesPageShow] = useState(false);
  
  const [stockCities, setStockCities] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [selectedCityStock, setCityStock] = useState("all");
  const handleSelectCity = e => {
    if (serCat === 0) {
      var sertext = document.getElementById("searchVenProd").value;
      if (sertext !== null && sertext.length > 2 && String(e.label).toLowerCase !== "all") {
        const filteredData = availableVendor.filter(userDa => userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) && userDa[2]?.toLowerCase().includes(String(e.label).toLowerCase()));
        setFillAvailableVendor(filteredData);
      }
      else
        if (e.value >= 0) {
          const filteredData = availableVendor.filter(userDa => userDa[2]?.toLowerCase().includes(String(e.label).toLowerCase()));
          setFillAvailableVendor(filteredData);
        }
        else {
          const filteredData = availableVendor.filter(userDa => userDa[1]?.toLowerCase().includes(sertext.toLowerCase()));
          // setFillAvailableVendor(availableVendor);
          setFillAvailableVendor(filteredData);
        }
    }
    setCityStock(e.label);
  }

  const customStyles = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      width: '6vw',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '.8vw',
      background: "white",
      border: '1px solid gray',
      minHeight: '1vw',
      lineHeight: '.5vw',

      "@media (max-width: 512px)": {
        fontSize: '3vw',
        lineHeight: '3vw',
      }

      // padding:'1vw',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      border: '1px solid black',
      borderRadius: '0px',
      fontSize: '1vw',
      minHeight: '.8vw',

      "@media (max-width: 512px)": {
        height: '6vw',
        fontSize: '3vw',
      }

      // width: '7vw',
      // paddingTop: '-20px',
      // marginTop: '.4rem',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingTop:'2vw',
      // marginTop:'2vw',
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      // marginTop: '-.1vw',

      "@media (max-width: 512px)": {
        marginTop: '-6%',
      }
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      marginTop: -5,
      width: 36,  // Set the desired width
      height: 36, // Set the desired height
      // Add any other styles you want for the dropdown indicator
      "@media (max-width: 512px)": {
        marginTop: '-10',
      }
    }),

  };


  const CustomMenu = props => {
    const maxHeight = 100; // Set your desired maximum height
    return (
      <components.Menu {...props}>
        <div style={{ maxHeight, overflowY: 'auto' }}>
          {props.children}
        </div>
      </components.Menu>
    );
  };


  const toggleDiv = (event) => {
    let allElems = document.querySelectorAll("#stockist_comp");
    let allElemsArr = Array.from(allElems);

    // console.log(allElemsArr)
    allElemsArr.forEach((e, i) => {
      e.style.height = "60px";
    })

    event.target.previousElementSibling.style.height = 'auto';
    if (event.target.nextElementSibling != null) {
      event.target.nextElementSibling.classList.toggle("hide");
    }
    if (event.target.id === "less") {
      event.target.previousElementSibling.classList.toggle("hide");
      // event.target.previousElementSibling.previousElementSibling.style.height = serCat === 0 ? '60px' : '30px';
    }
    event.target.classList.toggle("hide");
  }


  function clearSearch(serchEle="searchVenProd", serchCont = "searchProList") {
    var ser = document.getElementById(serchEle);
    if (ser !== null) {
      ser.value = '';
    }
    document.getElementById("cleanSearch").classList.replace("show", "hide");
    document.getElementById(serchCont).classList.replace("show", "hide");
  }

  function populateSearch(serchEle="searchVenProd", serchCont = "searchProList", getUnits = null) {
    var searchText = document.getElementById(serchEle);
    
    var ProductContainer = document.getElementById(serchCont);
    
    if (searchText.value.length > 2) {
      document.getElementById("cleanSearch").classList.replace("hide", "show");
      if (getUnits === "vendors")
        var serVenMedURL = 'http://43.205.199.21/digiPartnerAPI?vendProdAvail=0&user_Id=' + ReactSession.get("id")+"&stockCity=all&searchItem=" + searchText.value;
      else if (getUnits === "units")
        var serVenMedURL = "http://43.205.199.21/manageProdAPI?get_Units=" + searchText.value + "&dp_id=" + ReactSession.get('id');
      else if (getUnits === "companies")
        var serVenMedURL = "http://43.205.199.21/manageProdAPI?get_companies=" + searchText.value + "&dp_id=" + ReactSession.get('id');
      else
        var serVenMedURL = "http://43.205.199.21/selectProduct?dp_id=" + ReactSession.get('id') + "&sertext=" + searchText.value  
      
      setTimeout(
        function () {
          ProductContainer?.classList.replace("hide", "show");
          fetch(serVenMedURL, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            if (resp.status === "validityExpired") {
              alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
            }
            else {
              if (getUnits === "vendors")
              {
                setAvailableVendor(resp.vendProdData);
                setFillAvailableVendor(resp.vendProdData);
              }
              else
                setProductsData(resp.data);
            }
          });
        }, 2000)
    }
    else {
      ProductContainer?.classList.replace("show", "hide");
    }
  }

  const getQRCode = () => {
    setIsLoading(true);
    var apiURL = "http://43.205.199.21/digiPartnerAPI?getQRCode=1&user_Id=" + ReactSession.get("id");
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }

      if (res.status === "success") {
        setQRCode(res.qrCode);
        document.getElementById('Dialogue').classList.remove("hide");
      }
      else {
        // console.log("Already Exist");
        continueWithOrder();
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const continueWithOrder = () => {
    document.getElementById('Dialogue').classList.add("hide");
    setQRCode(null);
    createOrder();
  }

  const createOrder = () => {
    setIsLoading(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        digiPartCreateOrder: 1,
        dp_id: ReactSession.get("id"),
        cartTotals: totals,
        // digiOrderItems: digiCartItems,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }

        if (data.status === 'success') {
          setIsLoading(false);
          setShowModal(true);
          document.getElementById("successMessage").innerHTML = orderCompleteMessage;
          window.scrollTo(0, 0);
        }
        else {
          setIsLoading(false);
          alert("Error Placing Orders");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const deleteCartItem = (cartItemId) => {
    setIsLoading(true);
    if (cartItemId !== "all")
    {
      var delObject = {
        digiPartCartItemId: cartItemId,
        user_Id: ReactSession.get("id"),
      }
    }
    else
    {
      var delObject = {
        allItems: cartItemId,
        user_Id: ReactSession.get("id"),
      }
    }

    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(delObject)
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }

        if (data.status === 'success') {
          getDigiCartItems();
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
          alert("Error Deleting Ledgers");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const getDigiCartItems = () => {
    setIsLoading(true);
    var apiURL = "http://43.205.199.21/digiPartnerAPI?digiPartCartItems=1&user_Id=" + ReactSession.get("id");
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }

      if (res.status === "success") {
        // console.log("Trend Prod Length :", res.trendingProducts.length);
        setTrendFiles(res.trendfiles);
        setTrendProducts(res.trendingProducts);
        if (res.digiPartCartItems.length > 0) {
          setDigiCartItems(res.digiPartCartItems);
          setCartOrderComp(res.Companies);
          setTotals([res.MRP, res.PTR, res.QTY]);
        }
        else {
          setDigiCartItems(null);
        }

        var ordSucDetMSG = ""
        for (var i = 0; i < res.Companies.length; i++) {
          ordSucDetMSG += (res.Companies[i].split('___')[0] + ' - ');
          var tot = 0;
          var itmCo = 0;
          for (var j = 0; j < res.digiPartCartItems.length; j++) {
            if (res.Companies[i].split('___')[1] === res.digiPartCartItems[j][11]) {
              itmCo += 1;
              tot += (parseFloat(res.digiPartCartItems[j][8]) * parseFloat(res.digiPartCartItems[j][9]))
            }
          }
          ordSucDetMSG += (String(Number(tot).toFixed(2)) + " (" + String(itmCo) + " Items) </br>");
        }
        setOrderCompleteMessage(ordSucDetMSG);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
    });
  }


  const [prodImage, setProdImage] = useState(null);
  const [prodGallery, setProdGallery] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [prodData, setProdData] = useState(null);
  const [prodRatings, setProdRatings] = useState(null);
  const [prodSubstitute, setProdSubstitute] = useState(null);

  //Call API to retreive product details
  const getProductDetails = (searchItem = null) => {
    fetch("http://43.205.199.21/selectProduct?pro_id=" + searchItem + "&all=1", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      if (res.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else {
        setProdImage(res.image);
        setProdGallery(res.gallery);
        setGalleryData(res.galleryData);
        setCategories(res.categories);
        setProdData(res.data);
        setProdRatings(res.ratings);
        setProdSubstitute(res.substitutes);
        const base64String = res.gallery[res.galleryData[0][2]];
        setProductSelectedImage(`data:image/jpeg;base64,${base64String}`);
      }
    });
  }


  const getVendorWiseProd = (searchItem = null) => {
    // console.log("getVendorWiseProd executed");
    var apiURL = ''
    if (serCat === 0) { apiURL = 'http://43.205.199.21/digiPartnerAPI?vendProdAvail=0&user_Id=' + ReactSession.get("id"); }
    else { apiURL = 'http://43.205.199.21/digiPartnerAPI?vendProdAvail=1&searchItem=' + searchItem + "&user_Id=" + ReactSession.get("id"); }

    if (selectedCityStock != null)
      apiURL += "&stockCity=" + selectedCityStock;

    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }

      if (resp.vendProdData.length > 0) {
        if (serCat === 0) {
          setAvailableVendor(resp.vendProdData);
          setFillAvailableVendor(resp.vendProdData);
        }
        else
          setVendorWiseProdAvl(resp.vendProdData);

        document.getElementById("availability").innerHTML = "Available Vendors (" + String(resp.vendProdData.length) + ") >>";
        setAvalVendors(resp.vendProdData.length)
      }
      else {
        setAvailableVendor(null);
        setVendorWiseProdAvl(null);
        document.getElementById("availability").innerHTML = "";
        // document.getElementById("availability").innerHTML = "No suitable match found...!";
      }
      getProductDetails(searchItem);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err.message);
    });
  }


  const addToDPCarts = (cartItem, qty) => {
    setIsLoading(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        digiCart: 1,
        dp_id: ReactSession.get("id"),
        prod_id: cartItem[1],
        cartStockID: cartItem[20],
        quantity: qty,
        distType: cartItem[2] === "batch_no" ? 1 : 0,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }

        if (data.status === 'success') {
          getDigiCartItems();
        }
        else
          alert("Error Creating Voucher");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const searchProdVendorWise = (selVend) => {
    setSelectedVendor(selVend);
    // console.log("searchProdVendorWise Executing : ");
    // setSerCat(1);
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?vendProdAvail=3&user_Id=' + ReactSession.get("id") + '&distType=' + selVend[12] + '&distID=' + selVend[0];
    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }

      // console.log("Length : ", resp.vendProdData.length);
      if (resp.vendProdData.length > 0) {
        const prod = resp.vendProdData.filter(pro => pro[1] !== null)
        // console.log("Filterd :", prod.length);
        setVendorWiseProdAvl(prod);
        setFillVendorWiseProdAvl(prod);
        setVendorWiseProdImages(resp.images);
        document.getElementById("availability").innerHTML = "Available Products (" + String(prod.length) + ") >>";
      }
      else {
        // console.log("Else Running");
        setAvailableVendor(null);
        setFillVendorWiseProdAvl(null);
        setVendorWiseProdAvl(null);
        document.getElementById("availability").innerHTML = "No suitable match found...!";
      }
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      console.log(err.message);
    });
  }


  const getStockistCities = () => {
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?getStockistCities=1&user_Id=' + ReactSession.get("id");
    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else {
        setStockCities(res.stockCities);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }
  
  const [menuCollapse, setMenuCollapse] = useState(false);
  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }


  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getDigiCartItems();
          getVendorWiseProd();
          getStockistCities();
        }

  }, [serCat]);


  const [isProductImagesModalShow, setIsProductImagesModalShow] = useState(false);
  const [productSelectedImage, setProductSelectedImage] = useState();

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >

        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div className='searchVendHeader'>
            <div className='searchVendHeaderCol1'>
              <div style={{ display: 'flex' }}>
                {
                  selectedVendor && selectedVendor !== null ?
                    <>
                      <div className='dpDashSearchProductSelected' style={{ marginBottom: selectedVendor[1].length >= 20 ? "10px" : " 0px", marginTop: selectedVendor[1].length >= 20 ? "10px" : " 0px" }}>
                        <span className='dpDashSearchProductSelectedText' style={{}}>{selectedVendor[1]} | {selectedVendor[2]}</span>
                        <Tooltip title="Remove">
                          <button className='ProdCol' style={{ marginTop: '0' }} onClick={() => {
                            setSelectedVendor(null)
                            setSerCat(0);
                          }}>
                            <i className="ri-close-line" style={{ color: "red", fontSize: "25px", fontWeight: "bold" }}></i>
                          </button>
                        </Tooltip>
                      </div>
                    </>
                    :
                    <>
                      <div onClick={() => { setSerCat(0); document.getElementById('searchVenProd').value = ''; }} className='serMedVenAvlRadio'>
                        <div className='searchRadioBTN' style={{ backgroundColor: serCat === 0 ? "rgb(44, 156, 247)" : "gray" }}></div>
                        <div style={{ marginInline: '2%' }}>Vendor</div>
                      </div>

                      <div onClick={() => { setSerCat(1); document.getElementById('searchVenProd').value = '' }} className='serMedVenAvlRadio'>
                        <div className='searchRadioBTN' style={{ marginInline: '2%', backgroundColor: serCat === 1 ? "rgb(44, 156, 247)" : "gray" }}></div>
                        <div>Products</div>
                      </div>

                      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '40%' }}>
                        <img style={{ width: '8%', height: '65%' }} src={require('../../static/images/Icons/location1.png')} alt="" />
                        <div style={{ width: '100%', marginInline: '3%' }}>
                          {/* <div style={{ fontSize: '80%' }}>Location</div> */}
                          <Select options={stockCities} id="cat_idDD"
                            components={{ Menu: CustomMenu }}
                            menuPortalTarget={document.body}
                            styles={customStyles}
                            onChange={handleSelectCity}
                          />
                        </div>
                        {/* <div> <img style={{width:'35%'}} src ={require('../../static/images/Icons/down_arrow.png')} alt=""/></div> */}
                      </div>
                    </>
                }
              </div>
            </div>

            <div className='searchVendHeaderCol2'>
              <div className='serMedVenAvlRadio'>
                <div>My Cart</div>
              </div>
            </div>
          </div>

          <div className='searchSelectProduct'>
            <div className='searchSelectProductCol1'>
              <div className="DPsearch" style={{ justifyContent: 'space-between' }} >
                <input type="text" placeholder={serCat === 0 && selectedVendor === null ? "Search Vendors by Name...!" : "Search Products by Name...!"} name="searchVenProd" id="searchVenProd" autoComplete="off"
                  onKeyDown={(e) => {
                    if (selectedVendor !== null) {
                      const filData = vendorWiseProdAvl?.filter(data =>
                        data[10]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[11]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[12]?.toLowerCase().includes(e.target.value?.toLowerCase())
                      );
                      setFillVendorWiseProdAvl(filData);
                      
                      if (e.keyCode === 113)
                      {
                        AddProdModalShow();
                      }
                    }
                    else 
                    {
                      if (serCat === 0) {
                        if (e.keyCode === 113)
                        {
                          stockistModalShow(0);;
                        }

                        var sertext = document.getElementById("searchVenProd").value;
                        if (sertext !== null && (selectedCityStock === null || selectedCityStock?.toLowerCase() === "all")) {
                          const filteredData = availableVendor?.filter(userDa =>
                            userDa[1]?.toLowerCase().includes(sertext.toLowerCase())
                          );
                          setFillAvailableVendor(filteredData);
                        }
                        else
                          if (sertext !== null) {
                            const filteredData = availableVendor.filter(userDa =>
                              userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) &&
                              userDa[2]?.toLowerCase().includes(selectedCityStock?.toLowerCase())
                            );
                            setFillAvailableVendor(filteredData);
                          }
                      }
                      else {
                        populateSearch("searchVenProd", "searchProList");
                        if (e.keyCode === 113)
                        {
                          AddProdModalShow();
                        }

                        if (e.keyCode === 114)
                        {
                          console.log("Align Prod");
                          alignProdModalShow();
                        }

                        if (e.key === "Enter" || e.key === "ArrowDown") {
                          const searchPopup = document.getElementById("searchProList");
                          if (searchPopup.firstChild) {
                            searchPopup.firstChild.focus();
                          }
                        }
                      }
                    }
                  }}
                />

                <div className="cleanSearch hide" id="cleanSearch" onClick={clearSearch}><img src={require("../../static/images/Icons/close.png")} alt="" /></div>

                <div className="searchProList divshadow hide" id="searchProList">
                  {
                    productsData ?
                      productsData.length > 0 ?
                        productsData.map((product) =>
                          <div className='searchResModal topPartition' tabIndex={1} style={{ padding: '0px' }}
                            onClick={() => {
                              getVendorWiseProd(product[0]);
                              document.getElementById("searchProList").classList.replace("show", "hide");
                            }}

                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getVendorWiseProd(product[0]);
                                document.getElementById("searchProList").classList.replace("show", "hide");
                              }
                              else
                                if (e.key === "ArrowUp") {
                                  const focusedInput = document.activeElement;
                                  if (focusedInput.previousElementSibling) {
                                    focusedInput.previousElementSibling.focus();
                                  }
                                }
                                else
                                  if (e.key === "ArrowDown") {
                                    const focusedInput = document.activeElement;
                                    if (focusedInput.nextElementSibling) {
                                      focusedInput.nextElementSibling.focus();
                                    }
                                  }
                            }}>
                            <div style={{ display: 'block', paddingInline: '.5%' }}>
                              <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{product[1]}</div>
                              <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[2]}</div>
                              <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[8]}</div>
                              <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} > MRP : {product[5]} </div>
                            </div>
                          </div>
                        )
                        :
                        <div style={{fontSize:'1vw'}}>Searched product not found <br />Press <b>F2</b> to add new product <br />or <br/>Press <b>F3</b> to align product with vendor</div>
                      : 
                      null
                  }
                </div>
                <button style={{ backgroundColor: "black" }} type="submit"><i className="fa fa-search"></i></button>
              </div>

              {
                vendorWiseProdAvl && serCat === 1 && selectedVendor === null ?
                  <div style={{ width: '100%', borderBottom: '2px solid gray', paddingBlock: '1%', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                      <div style={{ fontSize: '130%', fontWeight: 'bold' }}>{vendorWiseProdAvl[0][10]}</div>
                      <div style={{ fontSize: '13px', color: "gray" }}>{vendorWiseProdAvl[0][11]}</div>
                      <div style={{ fontSize: '13px', color: "gray" }}>{vendorWiseProdAvl[0][12]}</div>
                      {/* <div style={{ fontSize: '13px', color: "gray" }}>Estimated MRP : {vendorWiseProdAvl[0][6]} | Estimated PTR : N/A</div> */}
                    </div>

                    <div className='searchMEdVanProdHeadRight' style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end", textAlign: "right" }}>
                      {
                        prodData && prodData[0][14] ?
                          <h3 style={{ fontSize: 12, color: "#2C9CF7", marginRight: -15 }} onClick={() => {
                            setIsSubstitutesPageShow(false);
                            setIsSaltInformationPageShow(true);
                          }}>
                            Salt Information <br /> <p style={{ color: "gray" }} >{prodData[0][14]?.length <= 35 ? `${prodData[0][14]}` : `${prodData[0][14]?.substring(0, 33)}...`} </p>
                          </h3> : null
                      }

                      <div style={{ display: "flex", alignItems: "center" }}>
                        {
                          prodSubstitute && prodSubstitute.length > 0 ?
                            <>
                              {
                                isSubstitutesPageShow === false ?
                                  <button style={{ marginTop: -20 }} onClick={() => setIsSubstitutesPageShow(true)}>{prodSubstitute.length} Substitutes</button>
                                  : null
                              }
                              {
                                isSubstitutesPageShow ?
                                  <>
                                    <button onClick={() => setIsSubstitutesPageShow(false)} id='avalVendorsBtn' style={{ marginTop: -20, marginLeft: 10 }}>Available Vendors {"(" + avalVendors + ")"}</button>   </>
                                  : null
                              }

                            </>
                            : null
                        }
                        {
                          isSaltInformationPageShow ?
                            <button onClick={() => setIsSaltInformationPageShow(false)} id='avalVendorsBtn' style={{ marginTop: -20, marginLeft: 10 }}>Available Vendors {"(" + avalVendors + ")"}</button>
                            : null
                        }
                      </div>
                    </div>

                  </div>
                  : null
              }

              {/* salt information */}

              {/* <div style={{ marginTop: '1%', fontSize: '80%' }} id="availability">Available {serCat === 0 ? 'Vendors ' : 'Products '} {' >>'}</div> */}
              <div style={{ marginTop: '1%', fontSize: '80%' }} id="availability">{serCat === 0 ? 'Vendors ' : 'Products'} {' >>'}</div>
              {isSubstitutesPageShow ?
                <>
                  <div className="substitites customScroll">
                    {prodSubstitute !== null ?
                      prodSubstitute.map((substitute) => {
                        return (
                          <div>
                            <div>
                              <h2 style={{ margin: 0, padding: 0 }}>{substitute[1]}</h2>
                              <p style={{ margin: 0, padding: 0 }}>{substitute[3]} | {substitute[2]}</p>
                              <p style={{ margin: 0, padding: 0 }}>Estimated MRP : {Number(substitute[4]).toFixed(2)} | Estimated PTR : {Number(substitute[5]).toFixed(2)}</p>
                            </div>
                          </div>
                        )
                      })
                      : null
                    }
                  </div>
                </>
                :
                isSaltInformationPageShow === false ?
                  <div className='DGVendProdAvl customScroll'>
                    {
                      selectedVendor !== null ?
                        <>
                        {
                        fillVendorWiseProdAvl ?
                          fillVendorWiseProdAvl.length < 1 ?
                            <div style={{fontSize:'1vw'}}> Searched product not found Press <b>F2</b> to align new product for selected Vendor</div>
                          :
                            fillVendorWiseProdAvl.map((vendorsProd, index) => {
                              var qty = 1;
                              var base64String = vendorWiseProdImages[vendorsProd[22]] === "None" ? "" : vendorWiseProdImages[vendorsProd[22]];
                              return (
                                <div style={{ padding: '1%', borderBottom: '1px solid gray', display: "flex" }}>
                                  <div className="searchImage">
                                    <img className='proImg' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                                      alt="products" onError={(e) => { e.target.src = defProdImage }} 
                                      onClick={() => {
                                        setIsProductImagesModalShow(true);
                                        getProductDetails(vendorsProd[1]);
                                      }}
                                      />
                                  </div>
                                  <div style={{ width: "100%" }}>
                                    <div className='selectedVendor'>
                                      <div className='selectedVendorTitle' style={{ lineHeight: "18px", marginBottom: "10px", height: "70%", marginTop: "5px" }}>{vendorsProd[10]} <br />
                                        <span style={{ fontSize: "13px", fontWeight: "400",color:"gray" }}>{vendorsProd[11]}</span>  <br />
                                        <span style={{ fontSize: "13px", fontWeight: "400", marginTop: "-10px",color:"gray" }}>{vendorsProd[12]}</span>
                                      </div>
                                      <div className='selectedVendorInStock' style={{fontSize:"14px"}}>Stock : {parseInt(vendorsProd[3]) < 1 ? "In Stock" : String(vendorsProd[3]).padStart(2, '0')} {parseInt(vendorsProd[3]) > 0 ? vendorsProd[3] < 2 ? "Unit" : "Units" : ""}</div>
                                    </div>

                                    <div className='searVendProdList'>
                                      <div style={{ marginBottom: "5px", fontSize:"14px",color:"gray" }}>MRP : {vendorsProd[4]} &nbsp;| &nbsp; PTR : { String(vendorsProd[5]) === '0' || vendorsProd[5] === null ? 'N/A' :<>{vendorsProd[21]}</> } &nbsp;| &nbsp; Expiry : {String(vendorsProd[5]) === '0' || vendorsProd[5] === null ? 'N/A' : <>{vendorsProd[5]}/{vendorsProd[6]}</>} </div>
                                      <div className='searVendProdListCol2'>

                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.nextElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) > 1 ? parseInt(divEl.innerText) - 1 : 1;
                                        }}> - </div>

                                        <div className='addQtyBtn' id="digiPartCartItemQty"> {qty} </div>

                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.previousElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) + 1;
                                        }}> + </div>

                                        <div className="btnPrimary1" onClick={(e) => {
                                          var qt = e.target.previousElementSibling.previousElementSibling.innerText;
                                          // var i_qty = document.getElementById("digiPartCartItemQty").value;
                                          addToDPCarts(vendorsProd, qt);
                                        }}>ADD</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                        : null
                        }
                        </>
                        :
                        serCat === 1 ?
                        <>
                          {/* Searched vendor product Data */}
                          {/* dp_id0, prod_Id1, batch_no2, stock_qty3, pro_mrp4, exp_month5, exp_year6, name7, mobile8, email9, pro_name10, unit11, 
                          pr.company12, CityName13, business_name14, mobile15, whatsapp_number16, ctx1_17, ctx2_18, ctx3_19 */}
                          {
                            vendorWiseProdAvl ?
                              vendorWiseProdAvl.map((vendorsProd, index) => {
                                var qty = 1;
                                return (
                                  <div style={{ padding: '1%', borderBottom: '1px solid gray' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div style={{ fontSize: '110%', fontWeight: 'bold' }}>{vendorsProd[14]}</div>
                                    </div>
                                    <div style={{ marginTop: "-6px", marginBottom: "8px",fontSize:"14px",color:"gray" }}>{vendorsProd[13]}</div>
                                    <div className='digiPartNerSearchedProductCon'>
                                      <div style={{ fontSize: "13px", textTransform: "uppercase" }}>
                                        <span style={{ color: "green", fontSize: "13px", lineHeight: "1" }}>Stock : &nbsp;
                                           {parseInt(vendorsProd[3]) < 1 ? "In Stock" : String(vendorsProd[3]).padStart(2, '0')}
                                           {parseInt(vendorsProd[3]) > 0 ? vendorsProd[3] < 2 ? "Unit" : "Units" : ""}</span>
                                        &nbsp; |  &nbsp;
                                        <span style={{ color: "#fc7526" }}>Scheme as applicable</span> <br />
                                        MRP : {vendorsProd[4]} &nbsp; | &nbsp; PTR : N/A &nbsp; | &nbsp;
                                        Expiry : {String(vendorsProd[5]) !== '0' ? <>{vendorsProd[5]}/{vendorsProd[6]}</> : 'N/A'}
                                      </div>
                                      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.nextElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) - 1;
                                        }}> - </div>

                                        <div className='addQtyBtn' id="digiPartCartItemQty"> {qty} </div>

                                        <div className='addQtyBtn' onClick={(e) => {
                                          var divEl = e.target.previousElementSibling;
                                          divEl.innerText = parseInt(divEl.innerText) + 1;
                                        }}> + </div>

                                        <div className="btnPrimary1" onClick={(e) => {
                                          var qt = e.target.previousElementSibling.previousElementSibling.innerText;
                                          // var i_qty = document.getElementById("digiPartCartItemQty").value;
                                          addToDPCarts(vendorsProd, qt);
                                        }}>ADD</div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                              :
                              trendProducts && trendProducts !== null ?
                                trendProducts.map((trendingProd, index) => {
                                  var qty = 1;
                                  var base64String = trendFiles[trendingProd[7]];
                                  return (
                                    <>
                                      {index === 0 ?
                                        <div className='trendProdHead'>Trending Products</div>
                                        : null
                                      }
                                      <div style={{ padding: '1%', borderBottom: '1px solid gray', display: "flex", color: "gray" }}>
                                        <div className="searchImage">
                                          <img className='proImg' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                                            alt="products" onClick={() => {
                                              getProductDetails(trendingProd[1]);
                                              setIsProductImagesModalShow(true);
                                            }} onError={(e) => { e.target.src = defProdImage }} />
                                        </div>
                                        <div style={{ width: "100%" }}>
                                          <div className='selectedVendor'>
                                            <div className='selectedVendorTitle' style={{ lineHeight: "18px", marginBottom: "10px", height: "70%", marginTop: "5px" }}>
                                              <span style={{ color: "#000", margin: 0, padding: 0, fontSize: 17 }}>{trendingProd[2]}</span> <br />
                                              <span style={{ fontSize: "13px", fontWeight: "400" }}>{trendingProd[4]}</span>  <br />
                                              <span style={{ fontSize: "13px", fontWeight: "400", marginTop: "-10px" }}>{trendingProd[3]}</span>
                                            </div>
                                          </div>

                                          <div className='searVendProdList'>
                                            <div style={{ marginBottom: "5px", fontSize: 13 }}>MRP : {trendingProd[5]} &nbsp;| &nbsp; PTR : {trendingProd[6]}</div>
                                            <div className='searVendProdListCol2'>
                                              {/* Add remove buttons here */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                                :
                                null
                          }

                        </>
                        : serCat === 0 ?
                        <>
                          {/* Array.from(new Set(originalArray)) */}
                          {/* user_id, business_name, CityName, name, landline_number, business_mobile, ctx1, ctx2, ctx3, prod_Id, company */}

                          {
                            fillAvailableVendor ?
                              fillAvailableVendor.length > 0 ?
                                fillAvailableVendor.map((vendorsProd, index) => {
                                  var companies = (Array.from(new Set((String(vendorsProd[10]).split(','))))).sort();
                                  return (
                                    <div className='seacrhItemList'>
                                      <div className='seacrhItemListCol1'>
                                        <div style={{ fontSize: '80%', color: 'rgb(78, 77, 77)' }}>
                                          <div style={{ display: 'flex' }}>
                                            <div className='searchVendName'>{vendorsProd[1]} | {vendorsProd[2]}
                                              {
                                                String(vendorsProd[13]) === "1" ?
                                                  <>
                                                    &nbsp;
                                                    <img id="serVendVerified1" src={require('../../static/images/Icons/success.png')} alt='' />
                                                  </>
                                                  : <div> </div>
                                              }

                                            </div>
                                          </div>

                                          {
                                            String(vendorsProd[24]) === '1' ?
                                              <div style={{color:"gray"}}>Owner Name : Mr./Mrs. {vendorsProd[3]}</div>
                                              : null
                                          }
                                          <div style={{color:"gray"}}>Business Address : {vendorsProd[14] ? vendorsProd[14] : ''} {vendorsProd[15] ? ', ' + vendorsProd[15] : ''} {vendorsProd[2] ? ', ' + vendorsProd[2] : ''} {vendorsProd[16] ? ', ' + vendorsProd[16] : ''} {vendorsProd[17] ? ', PinCode: ' + vendorsProd[17] : ''} </div>
                                          <div style={{color:"gray"}}>Drug License Number : {vendorsProd[18] ? String(vendorsProd[18]).split('___')[0] + '  |  ' + String(vendorsProd[18]).split('___')[1] : ''} {vendorsProd[19] ? ', ' + String(vendorsProd[19]).split('___')[0] + '  |  ' + String(vendorsProd[19]).split('___')[1] : ''} </div>
                                          {
                                            vendorsProd[20] ?
                                              <div style={{color:"gray"}}>GST Number : {vendorsProd[20]} </div>
                                              : null
                                          }

                                          {vendorsProd[4] !== null && String(vendorsProd[4]) !== '0' ?
                                            <div style={{ fontSize: '90%',color:"gray" }}>Landline :0{vendorsProd[21] + '-' + vendorsProd[4]} {vendorsProd[22] && String(vendorsProd[22]) !== '0' ? ', ' + vendorsProd[22] : ''} {vendorsProd[23] && String(vendorsProd[23]) !== '0' ? ', ' + vendorsProd[23] : ''}</div>
                                            : null
                                          }
                                          <div style={{ display: 'flex' }}>
                                            <div style={{ fontSize: '90%',color:"gray" }}>Contact : {vendorsProd[5]} {String(vendorsProd[25]).length >=10 ? " | Whatsapp :"+vendorsProd[25] : null}</div>
                                            <div style={{ fontSize: '90%',color:"gray" }}>&nbsp;&nbsp;{(vendorsProd[6] !== null && String(vendorsProd[6]) !== '0') || (vendorsProd[7] !== null && String(vendorsProd[7]) !== '0') ? " | CNTX :" : ''}
                                              {vendorsProd[6] != null && String(vendorsProd[6]) !== '0' ?
                                                <> | {vendorsProd[6]} </>
                                                : null} 
                                              {vendorsProd[7] != null && String(vendorsProd[7]) !== '0' ?
                                                <> | {vendorsProd[7]} </>
                                                : null }</div>
                                          </div>
                                        </div>
                                        {
                                          vendorsProd[10] !== null ?
                                            <>
                                              <div style={{ fontSize: '100%', marginTop: '1%' }} >Available Companies</div>
                                              <div style={{ display: 'flex', alignItems: 'flex-end',color:"gray" }}>
                                                <div id="stockist_comp" style={{ height: '60px', overflow: 'hidden' }}>
                                                  {
                                                    companies.map((company, index) => {
                                                      return <div style={{ fontSize: '80%', color: 'rgb(78, 77, 77)' }}>{company}</div>
                                                    })
                                                  }
                                                </div>
                                                {
                                                  companies.length > 3 ?
                                                    <div onClick={(e) => toggleDiv(e)} id="more" className='more_less'>
                                                      ...More
                                                    </div>
                                                    : null
                                                }

                                                <div onClick={(e) => toggleDiv(e)} id="less" className='more_less hide'>
                                                  ...Less
                                                </div>
                                              </div>
                                            </>
                                            :
                                            <>
                                              <div style={{ fontSize: '100%', marginTop: '1%' }} >Available Companies</div>
                                              <div style={{ fontSize: '100%' }} >N/A</div>
                                            </>
                                        }
                                      </div>
                                      <div className='seacrhItemListCol2'>
                                        {
                                          String(vendorsProd[13]) === "1" ?
                                            <div className='searchVendVerified'>
                                              <img id="serVendVerified" style={{ width: "2vw" }} src={require('../../static/images/Icons/success.png')} alt='' />
                                              <div style={{ float: 'left', color: 'white', fontSize: '80%', paddingBlock: '3px', paddingInline: '10px', backgroundColor: 'rgb(29, 139, 29)', borderRadius: '15px' }}>Verified</div>
                                            </div>
                                            : <div> </div>
                                        }
                                        {
                                          vendorsProd[10] !== null ?
                                            <div className='dpVendSerPro' onClick={() => { searchProdVendorWise(vendorsProd); }} >Search Products {'>>'}</div>
                                            : null
                                        }

                                        {
                                          vendorsProd[26] === ReactSession.get('id') ?
                                            <div className='dpVendSerPro' onClick={() => { searchProdVendorWise(vendorsProd); }} >Search Products {'>>'}</div>
                                            : null
                                        }

                                      </div>
                                    </div>
                                  )
                                })

                              :
                                <div style={{fontSize:'1.2vw'}}>Searched vendor not found, Press <b>F2</b> to add new vendor</div>
                            :
                              null
                          }
                        </>
                        :
                        null
                    }
                  </div>
                  :
                  <>
                    <div className="saltInformation customScroll" style={{ marginTop: 10 }}>
                      {prodData[0][15] !== null ? Parser(prodData[0][15]) : prodData[0][5] !== null ? prodData[0][5] : ''}
                    </div>
                  </>

              }

            </div>
            <div className='searchSelectProductCol2'>
              <div className='digiPartnerCarts digiCartTop'>
                {
                  digiCartItems !== null ?
                    <>
                      ESTIMATED PTR VALUE :{totals != null ? Number(totals[1]).toFixed(2) : ''} &nbsp;&nbsp;&nbsp;
                      <Tooltip title="Remove">
                        <button className='ProdCol' onClick={() => deleteCartItem("all")} >  
                          <span style={{ fontSize: '70%', backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '15px' }}>Clear</span>
                        </button>
                      </Tooltip>
                    </>
                    : null
                }
              </div>

              <div className='digiPartnerCarts customScroll' style={{ position: "relative", minHeight: '20vw', overflowY: 'scroll', overflowX: 'hidden' }}>
                {
                  digiCartItems && digiCartItems !== null ?
                    digiCartItems.map((data, index) => {
                      return (
                        <div key={index} className='cartColStyle'>
                          <div style={{ textAlign: 'left' }}>
                            <div style={{ fontSize: '80%', color: 'black' }}>{data[3]}</div>
                            <div style={{ fontSize: '70%' }}>{data[4]} | <b>Qty : {data[9]}</b></div>
                          </div>
                          <Tooltip title="Remove">
                            <button className='ProdCol' onClick={() => deleteCartItem(data[0])} >
                              <img src={require('../../static/images/Icons/close.png')} alt="" />
                            </button>
                          </Tooltip>
                        </div>
                      )
                    })
                    :
                    <div className='' style={{ minHeight: '20vw', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <img src={require("../../static/DPImages/blanckCart.png")} alt="" style={{ width: "30%" }} />
                      <p style={{ fontSize: "17px" }}>Your Cart is empty !</p>

                      <div className='btnPrimary2' style={{ backgroundColor: 'gray', position: "absolute", width: "100%", bottom: "3%", cursor: 'pointer' }}>Place Order</div>
                    </div>
                }

              </div>

              {
                digiCartItems !== null && digiCartItems.length > 0 ?
                  <>
                    <div className='digiPartnerCarts' style={{ backgroundColor: 'white' }}>
                      <div style={{ textAlign: 'left', fontSize: '75%' }}>
                        Orders will be placed with {cartOrderComp?.length} distributers:
                      </div>
                      {
                        <div style={{ display: 'flex', alignItems: 'flex-end', fontWeight: 'normal' }}>
                          <div id="stockist_comp" style={{ fontSize: '70%', height: '30px', overflow: 'hidden', textAlign: 'left' }}>
                            {
                              cartOrderComp?.map((company, index) => {
                                return <span className='digiPartnerCartsCompa'>{index + 1}. &nbsp; {company.split('___')[0]}</span>
                              })
                            }
                          </div>

                          <div onClick={(e) => toggleDiv(e)} id="more" className='more_less'>
                            ...More
                          </div>

                          <div onClick={(e) => toggleDiv(e)} id="less" className='more_less hide'>
                            ...Less
                          </div>
                        </div>
                      }
                    </div>
                  </>
                  :
                  null
              }

              <div className='digiPartnerCarts' style={{ background: "#fff" }}>
                {
                  digiCartItems !== null ?
                    <>
                      <div className='cartColStyle cartColStyleFirst' style={{ fontWeight: 'bold' }}>
                        <div>ESTIMATED MRP</div>
                        <div>{totals != null ? Number(totals[0]).toFixed(2) : ''}</div>
                      </div>

                      <div className='cartColStyle' style={{ fontWeight: 'bold' }}>
                        <div>ESTIMATED PTR</div>
                        <div>{totals != null ? Number(totals[1]).toFixed(2) : ''}</div>
                      </div>

                      <>
                        <div className='btnPrimary2' onClick={() => { getQRCode(); }} >Place Order</div>

                        <div className="placeOrderModalContainer" style={{ visibility: showModal ? "visible" : "hidden" }}>
                          <div className="placeOrderModal shadow-xl">
                            <i className="ri-close-line" onClick={() => {
                              setShowModal(false);
                              getDigiCartItems();
                            }} style={{ position: "absolute", right: "1%", top: "2%", fontSize: 25, cursor: "pointer" }}></i>
                            <img src={require("../../static/DPImages/placeOrder.png")} alt="" />
                            <h3 style={{ margin: "0px" }}>Order placed successfully</h3>
                            <p style={{ color: "gray" }}>Your Orders details have been send to your distibrutors</p>
                            <div style={{ borderTop: '2px solid gray' }} id="successMessage">
                              {/* {orderCompleteMessage} */}
                            </div>
                            <Link className='btnPrimary2' style={{ outline: 0, border: 0, width: "50%", textDecoration: "none", color: "#fff" }} to="/DigiPartneRetailOrders">View Order Details</Link>
                          </div>
                        </div>
                      </>
                    </>
                    :
                    <>
                      {/* <div className='btnPrimary2' style={{ backgroundColor: 'gray', cursor: 'auto' }}>Place Order</div> */}
                    </>
                }

                <div style={{ textAlign: 'left', fontSize: '70%', color: 'gray', fontWeight: "normal" }}>
                  Disclaimer <br />
                  Prices are indicative and may change after billing. Sheet360ERP is a technology platfrom to connect sellers and buyers and is not involved in sales of any product.
                  Offer for sale on the products and services are provided/sold by the sellers only. For more details read <span style={{ color: 'rgb(44, 156, 247)' }}>Terms & Conditions.</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        {isLoading ? <Loader /> : null}
      </div>

      <div className='Dialogue hide' id='Dialogue'>
        <div className='DialogueMSG'>
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <img src={require("../../static/DPImages/mainLogos/newLogo.png")} alt="" />
          <i style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>{
            document.getElementById('Dialogue').classList.add("hide");
          }}><MdOutlineClose /></i>
          </div>
          <hr />
          <div >
            <div className="details">

              <h3>Use whatsApp for your order</h3>

              <div className='flex'>
                <div>
                  <p style={{ marginTop: "40px" }}>1. Open whatsapp on your phone</p>
                  <p>2. Tab menu : on android , or  setting on iphone</p>
                  <p>3. Tab link devices and than link a device</p>
                  <p>4. Point your phone at this screen to capture the QR code</p>

                  <p style={{ marginTop: "30px", color: "#00C7E4" }} className='linkWithPhone'>Link with phone number</p>
                  {/* <Link to="/ContactUs" style={{ textDecoration: "none", color: "#00C7E4", textAlign: "center" }}>Need help ?</Link> */}
                  <span className='btnPrimary2' style={{padding:"10px 50px"}} onClick={() => {
                    continueWithOrder();
                  }}>Continue</span>
                </div>

                <div className="qrCode">
                  {QRCode && QRCode != null ?
                    <img src={QRCode} alt="" />
                    : null
                  }  </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* isProductImagesModalShow */}
      <Modal className='productImagesModal' show={isProductImagesModalShow} onHide={() => {
        setIsProductImagesModalShow(false);
      }} centered size="xl" backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <h3 style={{ fontSize: 25, color: "#000" }}>Product Images</h3>
            <span onClick={() => {
              setGalleryData(null);
              setProductSelectedImage(null); 
              setIsProductImagesModalShow(false);
            }} style={{ cursor: "pointer", fontSize: 25 }}><MdClose /></span>
          </div>

          <div className="d-flex align-items-start justify-content-between" style={{ gap: "10px" }}>
            <div className="images d-flex align-items-center flex-wrap">
              {
                galleryData ? galleryData.map((data, i) => {
                  const base64String = prodGallery[data[2]];
                  return (
                    <>
                      <img onClick={(e) => { setProductSelectedImage(`data:image/jpeg;base64,${base64String}`) }} className={`productImage-${i + 1} ${data[1]} mb-2`} src={`data:image/jpeg;base64,${base64String}`} alt="" />
                    </>
                  )
                }) : null
              }
            </div>

            <div className="image magnify-container" style={{ marginBottom: "50px", marginRight: "20px" }}>
              {/* <img className='mainImage' src={productSelectedImage} alt="" /> */}
              <ReactImageMagnify {...{
                smallImage: {
                  alt: 'Wristwatch by Ted Baker London',
                  isFluidWidth: true,
                  src: productSelectedImage,

                  className: 'mainImageClass' // Add your custom class here
                },
                largeImage: {
                  src: productSelectedImage,
                  width: 1200,
                  height: 1800
                },
                enlargedImageContainerDimensions: {
                  width: '100%',
                  height: '100%'
                },
                enlargedImageContainerClassName: 'large-image-container',
              }} className="mainImage" />

            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add new product Modal */}
      <Modal style={{background:"rgb(0, 0, 0, .3)"}} show={AddProdModal} onHide={AddProdModalClose} size="md" backdrop="static" keyboard={false}>
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{boxShadow:" 0 25px 50px -12px rgb(0 0 0 / 0.25)"}}>
          {isLoading ? 
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '9999999999', backgroundColor: 'rgba(157, 157, 157, 0.5)' }}>
              <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)', width: 100 }} src={require('../../static/Loader/Spinner2.png')} alt="" />
            </div> : null}

          {/* <form name="addProd" action="" method="post"> */}
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input style={{fontSize:"14px"}} className="digiPartLoginInput" name="newProdName" id="newProdName" type="text"
                  onKeyDown={(e) => {
                    removeErrorMessage("newProdName");
                    if (e.key === "Enter")
                    {
                      document.getElementById("newProdUnit").focus();
                    }
                  }} autoComplete="off" placeholder="Product Name...." autoFocus tabIndex={0}/>
                </div>
            </div>
          </div>
 
          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="newProdUnit" id="newProdUnit" type="text"
                  onKeyDown={(e) => {
                    removeErrorMessage("newProdUnit");
                    populateSearch("newProdUnit", "searchProdUnit", "units");

                    if (e.key === "Enter") {
                      document.getElementById("newProdComp").focus();
                    }

                    if (e.key === "ArrowDown") {
                      const searchPopup = document.getElementById("searchProdUnit");
                      if (searchPopup.firstChild) {
                        searchPopup.firstChild.focus();
                      }
                    }
                  }} placeholder="Unit...." tabIndex={1}/>
              </div>
            </div>
          </div>

          <div className="searchProdUnit customScroll divshadow hide" id="searchProdUnit" style={{height:'40vh', overflow:'scroll'}}>
            {
              productsData ?
                productsData.map((product) =>
                {  
                return (
                  <div className='searchResModal topPartition' tabIndex={1.1} style={{ padding: '0px' }}
                    onClick={() => {
                      document.getElementById("newProdUnit").value = product[0];
                      document.getElementById("searchProdUnit").classList.replace("show", "hide");
                      document.getElementById("newProdComp").focus();
                    }}

                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("newProdUnit").value = product[0];
                        document.getElementById("searchProdUnit").classList.replace("show", "hide");
                        document.getElementById("newProdComp").focus();
                      }
                      else
                        if (e.key === "ArrowUp") {
                          const focusedInput = document.activeElement;
                          if (focusedInput.previousElementSibling) {
                            focusedInput.previousElementSibling.focus();
                          }
                        }
                        else
                          if (e.key === "ArrowDown") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.nextElementSibling) {
                              focusedInput.nextElementSibling.focus();
                            }
                          }
                    }}>
                    <div style={{ display: 'block', paddingInline: '.5%' }}>
                      <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{product[0]}</div>
                    </div>
                  </div>
                )}
                )
                : null
            }
          </div>
          
          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input style={{fontSize:"13px"}} className="digiPartLoginInput" name="newProdComp" id="newProdComp" type="text"
                  onKeyDown={(e) => {
                    removeErrorMessage("newProdComp");
                    if (e.key === "Enter") {
                      document.getElementById("newProdMRP").focus();
                    }
                    populateSearch("newProdComp", "searchProdComp", "companies");
                    if (e.key === "ArrowDown") {
                      const searchPopup = document.getElementById("searchProdComp");
                      if (searchPopup.firstChild) {
                        searchPopup.firstChild.focus();
                      }
                    }
                  }} placeholder="Company Name...." tabIndex={2}/>
              </div>
            </div>
          </div>

          <div className="searchProdComp customScroll divshadow hide" id="searchProdComp" style={{height:'40vh', overflow:'scroll'}}>
            {
              productsData ?
              productsData.map((company) =>
                {  
                return (
                  <div className='searchResModal topPartition' tabIndex={1.1} style={{ padding: '0px' }}
                    onClick={() => {
                      document.getElementById("newProdComp").value = company[0];
                      document.getElementById("searchProdComp").classList.replace("show", "hide");
                      document.getElementById("newProdMRP").focus();
                    }}

                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("newProdComp").value = company[0];
                        document.getElementById("searchProdComp").classList.replace("show", "hide");
                        document.getElementById("newProdMRP").focus();
                      }
                      else
                        if (e.key === "ArrowUp") {
                          const focusedInput = document.activeElement;
                          if (focusedInput.previousElementSibling) {
                            focusedInput.previousElementSibling.focus();
                          }
                        }
                        else
                          if (e.key === "ArrowDown") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.nextElementSibling) {
                              focusedInput.nextElementSibling.focus();
                            }
                          }
                    }}>
                    <div style={{ display: 'block', paddingInline: '.5%' }}>
                      <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{company[0]}</div>
                    </div>
                  </div>
                )}
                )
                : null
            }
          </div>
          

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="newProdMRP" id="newProdMRP" type="text" placeholder="MRP...."
                  onKeyDown={(e) => {
                    removeErrorMessage("newProdMRP");
                    if (e.key === "Enter") {
                      validatAddProdForm();
                    }
                  }}  tabIndex={3}/>
              </div>
            </div>
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12" >
                <div className="digiSiginBtn" id="addNewProdBTN" tabIndex={4}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatAddProdForm();
                    }
                  }}
                  onClick={validatAddProdForm}>Add Product</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add new Vendor Modal */}
      <Modal show={stockistModal} onHide={stockistModalClose} size="md" backdrop="static">
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>{vendorType === 0 ? 'Add Stockist/CFA' : 'Add Customer'} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? 
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '9999999999', backgroundColor: 'rgba(157, 157, 157, 0.5)' }}>
              <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)', width: 100 }} src={require('../../static/Loader/Spinner2.png')} alt="" />
            </div> 
          : null}

          <div className="errorMessageHide"  id="errorMessage2">Red marked fields are mandatory</div>
          {/* <form name="addStockist" action="" method="post"> */}
          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/user.png')} alt="" />
                <input className="digiPartLoginInput" name="stockName" id="stockName" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("gstin").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("stockName") }} placeholder="Full Name...." autoFocus />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/gstin.png')} alt="" />
                <input className="digiPartLoginInput" name="gstin" id="gstin" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (vendorType === 0)
                        document.getElementById("dlNo").focus();
                      else
                        document.getElementById("eMail").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("gstin") }} placeholder="GSTIN...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/druglicense.png')} alt="" />
                <input className="digiPartLoginInput" name="dlNo" id="dlNo" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("dlNo2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("dlNo") }} placeholder="Drug License No...." /> {'|'}
                <input className="digiPartLoginInput" name="dlNo2" id="dlNo2" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("eMail").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("dlNo2") }} placeholder="Drug License No...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/email.png')} alt="" />
                <input className="digiPartLoginInput" name="eMail" id="eMail" type="email"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile").focus();
                    }
                  }}
                  placeholder="E-mail...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/smartphone.png')} alt="" />
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="mobile" id="mobile" type="text" size="4" maxLength="10"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("mobile") }} placeholder="Primary Contact Number...." /> {'|'}
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="mobile2" id="mobile2" type="text" size="4"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address1").focus();
                    }
                  }}
                  maxLength="10" placeholder="Secondary Contact Number...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="address1" id="address1" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("address1") }} placeholder="Address Line 1...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="address2" id="address2" type="text" placeholder="Address Line 2...."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("city").focus();
                    }
                  }} />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="city" id="city" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("state").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("city") }} placeholder="City...." />
                <input className="digiPartLoginInput" name="state" id="state" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("pincode").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("state") }} placeholder="State...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="pincode" id="pincode" type="text" size="4" maxLength="6"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegForm();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("pincode") }} placeholder="Postal Code...." />
              </div>
            </div>
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegForm();
                    }
                  }}
                  onClick={validatRegForm}>Create</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Align product with Vendor Modal */}
      <Modal style={{background:"rgb(0, 0, 0, .3)"}} show={alignProdModal} onHide={alignProdModalClose} size="md" backdrop="static" keyboard={false}>
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>Align Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{boxShadow:" 0 25px 50px -12px rgb(0 0 0 / 0.25)"}}>
          {isLoading ? 
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: '9999999999', backgroundColor: 'rgba(157, 157, 157, 0.5)' }}>
              <img style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -70%)', width: 100 }} src={require('../../static/Loader/Spinner2.png')} alt="" />
            </div> : null}

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <input name="alignProdID" id="alignProdID" type="text" hidden/> 
          <div style={{marginTop: '.2vw' }}>
            <input style={{fontSize:"14px"}} className="digiPartLoginInput" name="alignProdName" id="alignProdName" type="text"
              onKeyDown={(e) => {
                removeErrorMessage("alignProdName");

                if (e.keyCode === 113)
                {
                  AddProdModalShow();
                }

                populateSearch("alignProdName", "searchProdName");
                if (e.key === "Enter")
                {
                  document.getElementById("alignProdVend").focus();
                }
                if (e.key === "ArrowDown") {
                  const searchPopup = document.getElementById("searchProdName");
                  if (searchPopup.firstChild) {
                    searchPopup.firstChild.focus();
                  }
                }
              }} autoComplete="off" placeholder="Product Name...." autoFocus tabIndex={0}/>
            
            <div className="searchProdName customScroll divshadow hide" id="searchProdName" style={{height:'40vh',width:'30vw', marginTop:'-1px', overflow:'scroll'}}>
              {
                productsData ?
                productsData.length > 0 ?
                  productsData.map((product) =>
                  {  
                  return (
                    <div className='searchResModal topPartition' tabIndex={1.1} style={{ padding: '0px' }}
                      onClick={() => {
                        document.getElementById("alignProdID").value = product[0];
                        document.getElementById("alignProdName").value = product[1];
                        document.getElementById("searchProdName").classList.replace("show", "hide");
                        document.getElementById("alignProdVend").focus();
                      }}

                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          document.getElementById("alignProdID").value = product[0];
                          document.getElementById("alignProdName").value = product[1];
                          document.getElementById("searchProdName").classList.replace("show", "hide");
                          document.getElementById("alignProdVend").focus();
                        }
                        else
                          if (e.key === "ArrowUp") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.previousElementSibling) {
                              focusedInput.previousElementSibling.focus();
                            }
                          }
                          else
                            if (e.key === "ArrowDown") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.nextElementSibling) {
                                focusedInput.nextElementSibling.focus();
                              }
                            }
                      }}>
                      <div style={{ display: 'block', paddingInline: '.5%' }}>
                        <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{product[1]}</div>
                        <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[2]}</div>
                        <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{product[8]}</div>
                        <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} > MRP : {product[5]} </div>
                      </div>
                    </div>
                  )}
                  )

                  :
                  <div style={{fontSize:'1vw'}}>Searched product not found <br />Press <b>F2</b> to add new product</div>
                  : null
              }
            </div>
          </div>

          <input name="alignVendID" id="alignVendID" type="text" hidden/>
          <div style={{ marginTop: '.2vw' }}>
            <input className="digiPartLoginInput" name="alignProdVend" id="alignProdVend" type="text" placeholder="Select Vendor..."
                onKeyDown={(e) => {
                  removeErrorMessage("alignProdVend");
                  if (e.keyCode === 113)
                  {
                    stockistModalShow(0);;
                  }
                  populateSearch("alignProdVend", "searchVendors", "vendors");
                  
                  if (e.key === "ArrowDown") {
                    const searchPopup = document.getElementById("searchVendors");
                    if (searchPopup.firstChild) {
                      searchPopup.firstChild.focus();
                    }
                  }

                  // if (e.key === "Enter") {
                  //   validatAddProdForm();
                  // }
              }}  tabIndex={3}/>
            
            <div className="searchVendors customScroll divshadow hide" id="searchVendors" style={{height:'40vh', width:'30vw', marginTop:'-1px', overflow:'scroll'}}>
              {
                fillAvailableVendor ?
                fillAvailableVendor.length > 0 ?
                fillAvailableVendor.map((vendor, index) =>
                  {  
                  return (
                    <div key={index} className='searchResModal topPartition' tabIndex={1.1} style={{ padding: '0px' }}
                      onClick={() => {
                        document.getElementById("alignVendID").value = vendor[0];
                        document.getElementById("alignProdVend").value = vendor[1];
                        document.getElementById("searchVendors").classList.replace("show", "hide");
                      }}

                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          document.getElementById("alignVendID").value = vendor[0];
                          document.getElementById("alignProdVend").value = vendor[1];
                          document.getElementById("searchVendors").classList.replace("show", "hide");
                        }
                        else
                          if (e.key === "ArrowUp") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.previousElementSibling) {
                              focusedInput.previousElementSibling.focus();
                            }
                          }
                          else
                            if (e.key === "ArrowDown") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.nextElementSibling) {
                                focusedInput.nextElementSibling.focus();
                              }
                            }
                      }}>
                      <div style={{ display: 'block', paddingInline: '.5%' }}>
                        <div style={{ fontSize: '1rem', lineHeight: '1.25rem' }} >{vendor[1]}</div>
                        <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{vendor[2]}</div>
                        <div style={{ fontSize: '.8rem', lineHeight: '1.25rem' }} >{vendor[5]} </div>
                      </div>
                    </div>
                  )}
                  )

                  :
                  <div style={{fontSize:'1vw'}}>Searched Vendor not found <br />Press <b>F2</b> to add new Vendor</div>
                  : null
              }
            </div>
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12" >
                <div className="digiSiginBtn" id="alignNewProdBTN" tabIndex={4}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      alignProduct();
                    }
                  }}
                  onClick={alignProduct}>Align Product</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SearchMedVenAvl